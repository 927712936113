import { Button } from "@mui/material"
import { resetTimer, resetAttempts } from "entities/Assignment/api/assignment"

interface IProps {
  blockId: number
}

export const ResetTestButton = ({ blockId }: IProps) => {
  const userEmail = localStorage.getItem("email")
  const localStorageKey = `${userEmail}_test_${blockId}_result_modal`

  const handleClick = async () => {
    localStorage.removeItem(localStorageKey)
    await resetTimer(blockId)
    await resetAttempts(blockId)
    window.location.reload()
  }

  return <Button onClick={handleClick}>Очистить прохождение теста (только для тестирования) </Button>
}
